import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import User from '@/models/User';
import Bugsnag from '@bugsnag/js';

export const useUserStore = defineStore(
  'user',
  () => {
    const token = ref<string>();
    const user = ref<User>();
    const roles = ref<string[]>([]);
    function setUser(u: User) {
        user.value = u
        try {
            Bugsnag.setUser(u.id.toString(), u.email, u.name)
        }
        catch (e: any) {

        }
    }
    const delUser = () => {
      user.value = undefined
      roles.value = []
    }
    const setRoles = (rs: string[]) => {
        roles.value = rs
    }
    const hasRoles = (role: string[] | string) => {
        if(typeof role == 'string') {
            return roles.value.includes(role)
        } else {
            return haveCommonElement(roles.value, role)
        }
    }

    const financeAuthorized = computed(() => {
        return hasRoles(['root', 'manager', 'accountant', 'account_executive'])
    })

    function haveCommonElement(arr1: any[], arr2: any[]) {
        return arr1.some(element => arr2.includes(element));
    }

    const returnUrl = ref('')
    const setReturnUrl = (url: string) => (returnUrl.value = url)

    return { user, roles, financeAuthorized, token, setUser, delUser, setRoles, hasRoles, returnUrl, setReturnUrl }
  }
)
